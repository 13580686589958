import React, { Component } from 'react';
import { LangConsumer } from 'utils/LangProvider';
import Moment from 'moment';
import { bindLifecycle } from 'utils/bindLifecycle';
import webApp from './../../../webapp';
import ability from '../../../ability';
import SearchList from './SearchList';
import SearchPlay from './SearchPlay';
import pubsub from 'utils/pubsub';

class Search extends Component {
    /**用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.state = {
            orPlay: false,
            playIndex: -1,
            startTime: Moment(Moment().format('YYYY/MM/DD'), 'YYYY/MM/DD'), // 当天零点,
            endTime: Moment(Moment().format('YYYY/MM/DD') + ' 23:59:59', 'YYYY/MM/DD HH:mm:ss'),
            islock: false,
        };
        this.searchPlay = React.createRef();
    }

    componentDidMount () {
        // 批量下载功能通过IPCRecordBatchDownload产品定义进行控制，且高版本浏览器不支持该功能
        ability.get('IPCRecordBatchDownload').then(res => {
            if (res && webApp.playMode !== 'flash' && webApp.playMode !== 'h5') {
                return;
            }
        });
    }

    componentWillUnactivate () {
        // 在离开页面的时候停止图片查询
        pubsub.publish('ImagePlayBack_onLeave', true);
    }
    componentWillUnmount () {
        // 在离开页面的时候停止图片查询
        pubsub.publish('ImagePlayBack_onLeave', true);
    }

    changeState = (name, value) => {
        this.setState({
            [name]: value
        });
    }
    changePlayIndex = (index) => {
        this.setState({
            playIndex: index
        });
    }
    onChangeTranslate = (val, index = -1) => {
        this.setState({
            orPlay: val,
            playIndex: index
        });
    }

    changeLock = status => {
        this.setState({ islock: status });
    }
    render () {
        let { orPlay, playIndex, startTime, endTime, islock } = this.state;
        let {changeState, onChangeTranslate, changeLock, changePlayIndex} = this;
        return (
            <div className="tab-content">
                <div className={'search clearfix' + (orPlay ? ' toRight' : '')}>
                    <div className="search-list">
                        <SearchList
                            {...this.props}
                            {...{startTime, endTime}}
                            {...{ onChangeTranslate, changeLock,
                                onchangeTime: changeState
                            }}
                        />
                    </div>
                    <div className="search-play">
                        <SearchPlay ref={this.searchPlay}
                            {...this.props}
                            {...{startTime, endTime, playIndex, islock}}
                            {...{ onChangeTranslate, changeLock,
                                changeIndex: changePlayIndex
                            }}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

export default LangConsumer(bindLifecycle(Search));